import type { ColumnType } from 'antd/es/table';
import Big from 'big.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import type React from 'react';
import { dateFormatShow } from '../constants';
import { getOptionValueOrLabel } from '../edit/getOptionValueOrLabel';
import { ApplyItemMenuRender } from '../list/ApplyItemMenuRender';
import { BlurComponent } from '../myInfo/BlurComponent';
import type { useApartmentList } from '../overview/useApartmentList';
import AttachmentOperation from './AttachmentOperation';
import type { StatColumnType } from './StatColumnType';
import { generateSorter } from './generateSorter';

interface ColType<RecordType = unknown> extends ColumnType<RecordType> {
	isHaving?: () => boolean | undefined;
}

const extendRenderWithBig = (value: unknown): React.ReactNode => {
	if (value instanceof Big) {
		return <BlurComponent>{value.toString()}</BlurComponent>;
	}

	return <BlurComponent>{value}</BlurComponent>;
};

export function getStatColumn(
	stateParams: StatColumnType | undefined,
	uploadFinishedCallback: () => void,
	all?: boolean,
) {
	const tableColumns: ColType<
		NonNullable<ReturnType<typeof useApartmentList>['list'][0]>
	>[] = [
		{
			title: '房间号',
			dataIndex: 'roomNumber',
			width: 100,
			sorter: (a, b) => {
				return (
					Number.parseInt(a?.roomNumber ?? '0', 10) -
					Number.parseInt(b?.roomNumber ?? '0', 10)
				);
			},
		},
		{
			title: '总数',
			dataIndex: 'amount',
			width: 90,
			render: extendRenderWithBig,
			sorter: (a, b) => {
				return (
					Number.parseInt(a?.amount?.toString() ?? '0', 10) -
					Number.parseInt(b?.amount?.toString() ?? '0', 10)
				);
			},
		},
		{
			title: '租金',
			dataIndex: 'rent',
			width: 90,
			render: extendRenderWithBig,
			sorter: (a, b) => {
				return a.rent.sub(b.rent).toNumber();
			},
		},
		{
			title: '押金',
			dataIndex: 'deposit',
			width: 90,
			isHaving: () => stateParams?.column?.includes('showDeposit'),
			render: extendRenderWithBig,
			sorter: (a, b) => {
				return a.deposit.sub(b.deposit).toNumber();
			},
		},
		{
			title: '电费',
			dataIndex: 'totalElectricCharge',
			width: 80,
			sorter: (a, b) => {
				return a.totalElectricCharge.sub(b.totalElectricCharge).toNumber();
			},
			isHaving: () => stateParams?.column?.includes('showOtherFee'),
			render: extendRenderWithBig,
		},
		{
			title: '热水费',
			dataIndex: 'totalHotWaterCharge',
			width: 100,
			sorter: (a, b) => {
				return a.totalHotWaterCharge.sub(b.totalHotWaterCharge).toNumber();
			},

			isHaving: () => stateParams?.column?.includes('showOtherFee'),
			render: extendRenderWithBig,
		},
		{
			title: '冷水费',
			dataIndex: 'totalColdWaterCharge',
			width: 100,
			sorter: (a, b) => {
				return a.totalColdWaterCharge.sub(b.totalColdWaterCharge).toNumber();
			},
			isHaving: () => stateParams?.column?.includes('showOtherFee'),
			render: extendRenderWithBig,
		},
		{
			title: '卫生费',
			dataIndex: 'cleanFee',
			width: 100,
			sorter: (a, b) => {
				return a.cleanFee.sub(b.cleanFee).toNumber();
			},
			isHaving: () => stateParams?.column?.includes('showOtherFee'),
			render: extendRenderWithBig,
		},
		{
			title: '类别',
			dataIndex: 'category',
			render: (v) => {
				return getOptionValueOrLabel(v);
			},
			width: 60,
		},
		{
			title: '失效',
			dataIndex: 'chargeStatus',
			render: (v) => {
				return v === '0' ? '-' : '已被删除';
			},
			sorter: (a, b) => {
				return (
					(a?.chargeStatus ? parseInt(a?.chargeStatus) : 0) -
					(b?.chargeStatus ? parseInt(b?.chargeStatus) : 0)
				);
			},
			width: 100,
		},
		{
			title: '入住时间',
			width: 130,
			dataIndex: 'checkInTime',
			sorter: (a, b) => {
				return dayjs(a.checkInTime).valueOf() - dayjs(b.checkInTime).valueOf();
			},
			defaultSortOrder: 'descend',
			render: (v) => {
				return dayjs(v).format(dateFormatShow);
			},
		},
		{
			title: '单据编号',
			width: 130,
			dataIndex: 'uuid',
			isHaving: () => stateParams?.column?.includes('needUuid'),
		},
		{
			title: '单据状态',
			width: 130,
			dataIndex: 'chargeStatus',
			isHaving: () => !isNil(stateParams?.chargeStatus),
		},
		{
			title: '凭据',
			dataIndex: 'attachmentUrls',
			width: 130,
			render: (v, record) => {
				return (
					<AttachmentOperation
						attachmentUrl={v}
						record={record}
						uploadFinishedCallback={uploadFinishedCallback}
					/>
				);
			},
			sorter: (a, b) => {
				const calcValue = (dd: typeof a.attachmentUrls) =>
					dd.length > 0 ? -1 : 1;
				return calcValue(a.attachmentUrls) - calcValue(b.attachmentUrls);
			},
			isHaving: () => stateParams?.column?.includes('showAttachment'),
		},

		{
			title: '备注',
			dataIndex: 'remark',
			width: 300,
			sorter: (a, b) => {
				return (a?.remark?.length ?? 0) - (b?.remark?.length ?? 0);
			},
			isHaving: () => stateParams?.column?.includes('showRemark'),
		},

		{
			isHaving: () => stateParams?.column?.includes('showOperation'),
			title: 'count',
			dataIndex: 'logsCount',
			...generateSorter('logsCount'),
			width: 100,
			render: (v, record) => {
				return (
					// biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
					<div
						onClick={() => {
							location.href = `/apartment/edit?uuid=${record.uuid}&tab=log`;
						}}
					>
						{v}
					</div>
				);
			},
		},
		{
			isHaving: () => stateParams?.column?.includes('showUpdateTime'),
			title: '更新时间',
			width: 200,
			dataIndex: 'updateTime',
			...generateSorter('updateTime'),
		},
		{
			isHaving: () => stateParams?.column?.includes('showUpdateTime'),
			title: '记录时间',
			width: 150,
			dataIndex: 'recordingTime',
			...generateSorter('recordingTime'),
		},
		{
			isHaving: () => stateParams?.column?.includes('showUpdateTime'),
			title: '操作人',
			width: 150,
			dataIndex: 'operator',
			...generateSorter('operator'),
		},

		{
			isHaving: () => stateParams?.column?.includes('showAll'),
			title: '上月电表读数',
			dataIndex: 'electricMeterReadingLastMonth',
			width: 100,
		},
		{
			isHaving: () => stateParams?.column?.includes('showAll'),
			title: '本月电表读数',
			dataIndex: 'electricMeterReadingThisMonth',
			width: 100,
		},
		{
			isHaving: () => stateParams?.column?.includes('showAll'),
			title: '上月热水表读数',
			dataIndex: 'hotWaterMeterReadingLastMonth',
			width: 100,
		},
		{
			isHaving: () => stateParams?.column?.includes('showAll'),
			title: '本月热水表读数',
			dataIndex: 'hotWaterMeterReadingThisMonth',
			width: 100,
		},
		{
			isHaving: () => stateParams?.column?.includes('showAll'),
			title: '上月冷水表读数',
			dataIndex: 'coldWaterMeterReadingLastMonth',
			width: 100,
		},
		{
			isHaving: () => stateParams?.column?.includes('showAll'),
			title: '本月冷水表读数',
			dataIndex: 'coldWaterMeterReadingThisMonth',
			width: 100,
		},

		{
			title: '价格快照',
			width: 300,
			dataIndex: 'feeConfigSnapshotUuid',

			isHaving: () => stateParams?.column?.includes('showAll'),
		},
		{
			title: '热水单价',
			width: 100,
			render: (v, record) => {
				return v?.valuation?.hotWaterFee?.value;
			},
			isHaving: () => stateParams?.column?.includes('unitPrice'),
		},
		{
			title: '冷水单价',
			width: 100,
			render: (v, record) => {
				return v?.valuation?.coldWaterFee?.value;
			},
			isHaving: () => stateParams?.column?.includes('unitPrice'),
		},
		{
			title: '电费单价',
			width: 100,
			render: (v, record) => {
				return v?.valuation?.electricityFee?.value;
			},
			isHaving: () => stateParams?.column?.includes('unitPrice'),
		},

		{
			isHaving: () => stateParams?.column?.includes('showOperation'),
			title: '操作',
			fixed: 'right',
			width: 120,
			render: (v, record) => {
				return <ApplyItemMenuRender text={v} record={record} />;
			},
		},
	];

	if (all === true) {
		return tableColumns;
	}

	return tableColumns.filter((i) => {
		return i?.isHaving ? i?.isHaving?.() : true;
	});
}
