import { useLocalStorageState } from 'ahooks';
import {
	Button,
	Checkbox,
	Descriptions,
	Form,
	Input,
	InputNumber,
	Space,
	Table,
	Tabs,
	Typography,
	message,
} from 'antd';
import Big from 'big.js';
import dayjs from 'dayjs';
import type ExcelJS from 'exceljs';
import { groupBy } from 'lodash-es';
import { includeMonthly } from 'blog/type-file';
import { roomStatusEnumeration } from 'blog/type-file';
import { isValidElement } from 'react';
import { useUserAgent } from '../../../utils/userAgent';
import { constantCheckIn, constantLease } from '../edit/categorySelectOption';
import { useCopyFunc } from '../print/useCopyFunc';
import { useBuildingConfig } from '../useBuildingConfig';
import { MathRound2 } from '../util/MathRound2';
import { globalAntdPagination } from '../util/globalAntdPagination';
import type { StatColumnType } from './StatColumnType';
import { calcTotal } from './calcTotal';
import type { currentStatType } from './currentStatType';
import { excelExport } from './excelExport';
import { getStatColumn } from './getStatColumn';
import { TrpcBase } from '../../../utils/trpc';
import { BlurComponent } from '../myInfo/BlurComponent';

const { Text, Link } = Typography;

export function CurrentStat({
	list,
	loading,
	uploadFinishedCallback,
	excelName,
	currentTime,
}: currentStatType) {
	const { formLayout } = useUserAgent();
	const { currentBuildingConfig } = useBuildingConfig();
	useCopyFunc();

	const [stateParams, setStateParams] = useLocalStorageState<StatColumnType>(
		'stat-state',
		{
			defaultValue: {
				category: [includeMonthly],
				column: [],
				chargeStatus: '0',
			},
		},
	);

	const rooms = TrpcBase.apartment.getRooms.useQuery();

	const originSource = list
		.filter((i) => {
			if (
				stateParams?.amountSearch &&
				stateParams?.amountSearch?.toString() !== '' &&
				i.amount.toString().includes(stateParams.amountSearch.toString()) ===
					false
			) {
				return false;
			}

			return true;
		})
		.filter((i) => {
			if (stateParams?.uuidSearch && !i.uuid.includes(stateParams.uuidSearch)) {
				return false;
			}

			return true;
		});

	const stat = calcTotal(originSource);

	const tableColumns = getStatColumn(stateParams, uploadFinishedCallback);

	const statData = [
		{
			label: '单据数量',
			value: originSource.length,
		},
		{
			label: '总计',
			value: stat.total,
		},
		{
			label: '总租金',
			value: stat.totalRent,
		},
		{
			label: '总押金',
			value: stat.totalDeposit,
		},
		{
			label: '电费',
			value: stat.totalElectricCharge,
		},
		{
			label: '电量用度',
			value: stat.electronUsed,
		},
		{
			label: '冷水费用',
			value: stat.totalColdWaterCharge,
		},
		{
			label: '冷水用度',
			value: stat.coldUsed,
		},
		{
			label: '热水费用',
			value: stat.totalHotWaterCharge,
		},
		{
			label: '热水用度',
			value: stat.hotUsed,
		},
		{
			label: '去掉小数总计',
			value: stat.shouldTotal,
		},
	];
	return (
		<>
			<Form
				layout={formLayout}
				style={{ marginBottom: 16 }}
				initialValues={stateParams}
				onValuesChange={(v, all) => {
					return setStateParams((pre) => {
						return {
							...pre,
							...all,
						};
					});
				}}
			>
				<Form.Item label="uuid 检索" name="uuidSearch">
					<Input />
				</Form.Item>
				<Form.Item label="总数" name="amountSearch">
					<InputNumber />
				</Form.Item>

				<Form.Item label="显示" name="column" className="overflow-y-auto">
					<Checkbox.Group
						options={[
							{ label: '单据编号', value: 'needUuid' },
							{ label: '水电费用', value: 'showOtherFee' },
							{ label: '操作', value: 'showOperation' },
							{ label: '押金', value: 'showDeposit' },
							{ label: '备注', value: 'showRemark' },
							{ label: '凭据', value: 'showAttachment' },
							{ label: '更新时间', value: 'showUpdateTime' },
						]}
					/>
				</Form.Item>

				{/* <Form.Item label="状态" name="chargeStatus">
          <Select options={[{ label: '正常', value: '0' }, { label: '已失效', value: '1' }]} />
        </Form.Item> */}
			</Form>
			<Table
				pagination={globalAntdPagination}
				loading={loading}
				columns={tableColumns}
				dataSource={originSource}
				className="overflow-y-auto"
				scroll={{ x: 1500 }}
			/>

			<Tabs
				defaultActiveKey="1"
				items={[
					{
						key: '1',
						label: '结算模块',
						children: (
							<Descriptions>
								{statData.map((i) => {
									return (
										<Descriptions.Item key={i.label} label={i.label}>
											<BlurComponent>{i.value.toString()}</BlurComponent>
										</Descriptions.Item>
									);
								})}
							</Descriptions>
						),
					},

					{
						key: '2',
						label: '计算过程',
						children: (
							<Space direction={'vertical'}>
								<div>{dayjs().format('YYYY-MM-DD HH:mm')}</div>

								<div>
									{Object.entries(
										groupBy(originSource, (i) => i.floorLevel),
									).map((entry) => {
										const [key, value] = entry;
										return (
											<div key={key}>
												<div className="flex flex-row">
													<div>{key}楼总计:</div>
													<BlurComponent>
														{MathRound2(
															value.reduce((total, item) => {
																const a = total + item.amount.toNumber();
																return a;
															}, 0),
														)}
													</BlurComponent>
												</div>

												<BlurComponent>
													{value.map((i) => ` ${i.amount} `).join(' + ')}
												</BlurComponent>
												<BlurComponent>
													{value
														.map((i) => `(${i.roomNumber}房) ${i.amount} `)
														.join(' + ')}
												</BlurComponent>
												<br />
											</div>
										);
									})}
								</div>
							</Space>
						),
					},

					{
						key: '3',
						label: '快照',
						children: (
							<Space>
								<Button
									onClick={() => {
										const mCol = getStatColumn(
											stateParams,
											uploadFinishedCallback,
											true,
										);
										const rows = list.map((item, index) => {
											return mCol.map((columnDefine) => {
												const value = item[columnDefine.dataIndex];

												if (value instanceof Big) {
													return value.toNumber();
												}

												return columnDefine?.render
													? isValidElement(
															columnDefine?.render(value, item, index),
														)
														? '-'
														: columnDefine?.render(value, item, index)
													: value;
											});
										});

										const nData = rooms?.data?.map((i, idx) => {
											const current = rows
												.find((a) => a[0] === i.roomNumber)
												?.splice(1);
											const baseCol = [
												i.roomNumber,
												i.roomStatus === roomStatusEnumeration.busy
													? '租赁'
													: '空置',
												{ formula: `SUM(E${idx + 3}:J${idx + 3})` },
											];

											if (current) {
												return [...baseCol, ...current];
											}

											return baseCol;
										});
										const sum = {
											header: '加和',
											key: 'sum',
											width: 10,
										} as Partial<ExcelJS.Column>;

										const status = {
											header: '状态',
											key: 'status',
											width: 10,
										} as Partial<ExcelJS.Column>;

										const headerTitle = mCol.map((i) => {
											return {
												header: i.title,
												key: i.dataIndex,
												width: 10,
											} as Partial<ExcelJS.Column>;
										});

										headerTitle.splice(1, 0, status);
										headerTitle.splice(1, 0, sum);

										console.log(headerTitle);
										excelExport(
											headerTitle,
											nData,
											`${excelName}-${currentTime}`,
										);
									}}
								>
									excel 导出
								</Button>

								<Button
									onClick={() => {
										message.success('复制成功');
									}}
									className={'copy'}
									data-clipboard-text={`${
										currentBuildingConfig?.buildingName ?? ''
									}\n${dayjs().format('YYYY-MM-DD HH:mm')}\n${statData.map(
										(i) => {
											return `${i.label}:${i.value.toString()}\n`;
										},
									)}`.trim()}
								>
									总结复制
								</Button>
							</Space>
						),
					},
					{
						key: '4',
						label: '入住退租数量',
						children: (
							<>
								<Space direction="vertical" style={{ marginBottom: 30 }}>
									{[
										{ action: '入住', var: constantCheckIn },
										{
											action: '退租',
											var: constantLease,
										},
									].map((type) => {
										return (
											<Text key={type.action}>
												{currentTime}
												{type.action}
												{list.filter((i) => i.category === type.var).length}
												个房间:
												{list
													.filter((i) => i.category === type.var)
													.map((i) => i.roomNumber)
													.join(',')}
											</Text>
										);
									})}
								</Space>
							</>
						),
					},

					{
						key: '5',
						label: '其他',
						children: (
							<Space direction={'vertical'}>
								<div>{dayjs().format('YYYY-MM-DD HH:mm')}</div>

								<div>
									{Object.entries(
										groupBy(originSource, (i) => i.floorLevel),
									).map((entry) => {
										const [key, value] = entry;
										return (
											<div key={key}>
												<div className="flex flex-row">
													<div>{key}楼总计:</div>
													<BlurComponent>
														{MathRound2(
															value.reduce((total, item) => {
																const a = total + item?.networkFee.toNumber();
																return a;
															}, 0),
														)}
													</BlurComponent>
												</div>

												<div>
													<BlurComponent>
														{value
															.map(
																(i) =>
																	`${i.roomNumber}房网络费:${
																		i?.networkFee ?? 0
																	}; 电视费:${i?.tvFee ?? 0}`,
															)
															.join(' + ')}
													</BlurComponent>
												</div>
												<br />
											</div>
										);
									})}
								</div>
							</Space>
						),
					},
				]}
			/>
		</>
	);
}
