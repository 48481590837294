import { Card, Form } from 'antd';
import dayjs, { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useCallback } from 'react';
import { useOverviewState } from '../overview/useOverviewState';
import { useBuildingConfig } from '../useBuildingConfig';
import { CurrentStat } from './CurrentStat';
import { useControlPanel } from './useControlPanel';
extend(isBetween);

export default function Stat() {
	const { list, panel, requestAction } = useControlPanel();

	const { changeTitle, currentBuildingConfig } = useBuildingConfig();
	changeTitle('账单核对');

	const [stateParams, setStateParams] = useOverviewState();
	// useWhyDidYouUpdate('Stat', { stateParams, changeTitle, list, panel, requestAction });

	const [form] = Form.useForm();
	const formTime = Form.useWatch('time', form);
	const formFloor = Form.useWatch('floor', form);
	const formCategory = Form.useWatch('category', form);
	const time = dayjs(stateParams?.time);

	const { isLoading, refetch, isFetching } = requestAction;

	const whenUploaded = useCallback(() => {
		refetch();
		console.log('上传完成');
	}, [refetch]);

	return (
		<Card>
			{panel}

			<CurrentStat
				floorNumber={formFloor}
				list={list}
				loading={isFetching}
				currentTime={formTime ? formTime.format('YYYY-MM') : '最近 100 条'}
				excelName={`${currentBuildingConfig?.buildingName}`}
				uploadFinishedCallback={whenUploaded}
			/>
		</Card>
	);
}
