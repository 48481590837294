import useAttachmentHook from '../edit/useAttachmentHook';

export default function AttachmentOperation({
	attachmentUrl,
	record,
	uploadFinishedCallback,
}: {
	attachmentUrl: any[];
	record: any;
	uploadFinishedCallback: () => void;
}) {
	const { Dom, previewDom } = useAttachmentHook(
		record.uuid,
		true,
		uploadFinishedCallback,
		50,
	);

	if (attachmentUrl.length > 0) {
		return previewDom;
	}

	return Dom;
}
