import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const excelExport = async (
	columns: Partial<ExcelJS.Column>[],
	rows: unknown[],
	name: string,
): Promise<void> => {
	const workbook = new ExcelJS.Workbook();
	workbook.creator = 'ihook';
	workbook.lastModifiedBy = 'ihook';
	workbook.created = new Date();
	workbook.modified = new Date();

	const worksheet = workbook.addWorksheet(`${name}`);
	worksheet.columns = columns;
	worksheet.addRows(rows);

	const rowValues = [];
	rowValues[1] = '总计';
	rowValues[2] = { formula: `SUM(C3:C${rows.length + 3})` };
	// worksheet.addRow(rowValues);
	worksheet.insertRow(1, rowValues);

	workbook.xlsx
		.writeBuffer()
		.then((xls64) => {
			// build anchor tag and attach file (works in chrome)
			const a = document.createElement('a');
			const data = new Blob([xls64], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});

			const url = URL.createObjectURL(data);
			a.href = url;
			a.download = `${name}_at_${dayjs().format('MMDDHHmm')}.xlsx`;
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}, 0);
		})
		.catch((error) => {
			console.log(error.message);
		});
};
